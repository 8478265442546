// Avoid `console` errors in browsers that lack a console.
(function () {
    var method;
    var noop = function () {
    };
    var methods = [
        'assert', 'clear', 'count', 'debug', 'dir', 'dirxml', 'error',
        'exception', 'group', 'groupCollapsed', 'groupEnd', 'info', 'log',
        'markTimeline', 'profile', 'profileEnd', 'table', 'time', 'timeEnd',
        'timeline', 'timelineEnd', 'timeStamp', 'trace', 'warn'
    ];
    var length = methods.length;
    var console = (window.console = window.console || {});

    while (length--) {
        method = methods[length];

        // Only stub undefined methods.
        if (!console[method]) {
            console[method] = noop;
        }
    }

}());

$(document).ready(function () {

    $('.scrollTo').on('click', function (e) {
        e.preventDefault();

        //var target = this.hash;
        var target = $(this).data('target');
        var $target = $(target);

        $('html, body').stop().animate({
            'scrollTop': $target.offset().top
        }, 900, 'swing');
    });

    $('input').iCheck({
        checkboxClass: 'icheckbox_flat-blue',
        radioClass: 'iradio_flat-blue'
    });    
    
    $('[data-toggle="tooltip"]').tooltip();
    
    $('input.cc-num').payment('formatCardNumber');
    $('input.cc-exp').payment('formatCardExpiry');
    $('input.cc-cvc').payment('formatCardCVC'); 
    
    function addEvent(obj, evt, fn) {
        if (obj.addEventListener) {
            obj.addEventListener(evt, fn, false);
        }
        else if (obj.attachEvent) {
            obj.attachEvent("on" + evt, fn);
        }
    }
    
//    addEvent(document, "mouseout", function(e) {
//        e = e ? e : window.event;
//        var from = e.relatedTarget || e.toElement;
//        if (!from || from.nodeName == "HTML") {
//            if(! localStorage.getItem("beforeLeave") ) {
//                localStorage.setItem("beforeLeave", true);
//                $('#beforeLeave').modal();            
//            }
//            //localStorage.removeItem("beforeLeave");            
//        }
//    });

//    var slider = $('.bxslider').bxSlider({
//        auto: true,
//        autoStart: true,
//        autoHover: true,
//        mode: 'horizontal'
//    });
    
//    $('.benefits').click( function() {
//        slider.goToSlide(2);
//    });

});

$(window).load(function() { 
    $('#slider').nivoSlider({
        pauseTime: 4000
    });
    
}); 

